import { Link, StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "./Header.scss"
import Logo from "../../images/timekeeping-logo.png"

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = { mobileMenuVisible: false }
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this)
  }

  toggleMobileMenu() {
    this.setState(state => ({
      mobileMenuVisible: !state.mobileMenuVisible,
    }))
  }

  render() {
    const { siteTitle } = this.props
    return (
      <StaticQuery
        query={graphql`
          query HeaderContentQuery {
            wordpressWpApiMenusMenusItems(slug: { eq: "primary-navigation" }) {
              items {
                title
                url
                object_slug
                classes
                type
              }
            }
          }
        `}
        render={data => (
          <header className="header">
            <nav
              className="navbar"
              role="navigation"
              aria-label="main navigation"
            >
              <div className="container is-fluid">
                <div className="navbar-brand">
                  <Link to="/" className="navbar-item">
                    <img src={Logo} alt={`${siteTitle} Logo`} />
                  </Link>

                  <button
                    className={`navbar-burger burger ${
                      this.state.mobileMenuVisible ? "is-active" : ""
                    }`}
                    aria-label="menu"
                    aria-expanded="false"
                    data-target="navbarBasicExample"
                    onClick={this.toggleMobileMenu}
                  >
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                  </button>
                </div>

                <div
                  className={`navbar-menu ${
                    this.state.mobileMenuVisible ? "is-active" : ""
                  }`}
                >
                  <div className="navbar-end">
                    {data.wordpressWpApiMenusMenusItems.items.map((item, i) => {
                      const link =
                        item.type === "taxonomy"
                          ? item.url.replace(
                              "https://webadmin.tensorplc.com/timekeeping/category",
                              ""
                            )
                          : item.url.replace(
                              "https://webadmin.tensorplc.com/timekeeping",
                              ""
                            )
                      if (item.classes === "button") {
                        return (
                          <div className="navbar-item" key={i}>
                            <div className="buttons">
                              <Link to={link} className="button is-link">
                                {item.title}
                              </Link>
                            </div>
                          </div>
                        )
                      }
                      return (
                        <Link to={link} className="navbar-item" key={i}>
                          {item.title}
                        </Link>
                      )
                    })}
                  </div>
                </div>
              </div>
            </nav>
          </header>
        )}
      />
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
