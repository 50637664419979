import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa"
import "./Footer.scss"
import Logo from "../../images/timekeeping-logo-white.png"

const tensorLinks = [
  { title: "Access Control", slug: "access-control" },
  { title: "Visitor Monitoring", slug: "visitor-monitoring" },
  { title: "Human Resources", slug: "human-resources" },
  { title: "CCTV", slug: "cctv" },
  { title: "Time & Attendance", slug: "time-and-attendance" },
]

const Footer = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query FooterContentQuery {
      allWordpressPost(limit: 5) {
        edges {
          node {
            id
            link
            slug
            title
          }
        }
      }
    }
  `)

  return (
    <footer className="footer">
      <div className="container is-fluid">
        <div className="columns">
          <div className="column is-3">
            <img src={Logo} alt={`${siteTitle} Logo`} />
          </div>
          <div className="column is-7 is-offset-2">
            <div className="columns">
              <div className="column">
                <h4>Time Keeping</h4>
                <ul className="is-marginless">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/news">Latest News</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
              <div className="column">
                <h4>Latest News</h4>
                <ul>
                  {data.allWordpressPost.edges.map(({ node: post }, i) => (
                    <li key={i}>
                      <Link to={`/news/${post.slug}`}>{post.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="column">
                <h4>Tensor plc</h4>
                <ul>
                  {tensorLinks.map((post, i) => (
                    <li key={i}>
                      <a
                        href={`https://www.tensor.co.uk/${post.slug}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {post.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="columns footer-bottom">
          <div className="column">
            <p>
              &copy; {new Date().getFullYear()} {siteTitle}. All rights
              reserved.
            </p>
          </div>
          <div className="column has-text-left has-text-right-tablet social-icons">
            <a
              href="https://www.facebook.com/tensorplc"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF />
            </a>
            <a
              href="https://www.twitter.com/tensorplc"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter />
            </a>
            <a
              href="http://uk.linkedin.com/pub/tensor-plc/3b/94a/842"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedinIn />
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
