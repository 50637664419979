import React from "react"
import "./BookADemo.scss"

const BookADemo = () => {
  return (
    <div className="book-a-demo-banner">
      <p className="is-size-2-desktop is-size-3-tablet is-size-5 is-uppercase has-text-weight-semibold">
        Why not book a demo?
        <br /> +44 (0)1480 215530
      </p>
    </div>
  )
}

export default BookADemo
